import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Container from '../../layout/Container';
import Heading from '../../elements/Heading/Heading.component';
import Blurb from '../../elements/Blurb/Blurb.component';
import FadeIn from '../../utils/FadeIn';
import * as S from './aboutUs.styled';

const AboutUs = ({ data, invertBg }) => {
  const {
    tagline,
    heading,
    paragraph1,
    paragraph2,
    blurbs,
    image1,
    image2,
    image3,
  } = data;

  const img1 = getImage(image1.image);
  const img2 = getImage(image2.image);
  const img3 = getImage(image3.image);

  return (
    <S.AboutUs invertBg={invertBg} id="about-us">
      <Container className="container">
        <div className="images">
          <FadeIn>
            <div className="image-short">
              <GatsbyImage
                image={img2}
                alt={image2.imageAlt}
                style={{
                  display: 'block',
                  margin: '0 auto',
                }}
              />
              <GatsbyImage
                image={img3}
                alt={image3.imageAlt}
                style={{
                  display: 'block',
                }}
              />
            </div>
          </FadeIn>
          <FadeIn>
            <div className="image-long">
              <GatsbyImage
                image={img1}
                alt={image1.imageAlt}
                style={{
                  display: 'block',
                }}
              />
            </div>
          </FadeIn>
        </div>
        <div className="contents u-max-width-70">
          <FadeIn>
            <Heading tagline={tagline} title={heading} />
          </FadeIn>
          {paragraph1 && (
            <FadeIn>
              <p>{paragraph1}</p>
            </FadeIn>
          )}
          {paragraph2 && (
            <FadeIn>
              <p>{paragraph2}</p>
            </FadeIn>
          )}
          {blurbs.length > 0 &&
            blurbs.map((item) => {
              const { icon, title, description } = item;
              return (
                <FadeIn>
                  <Blurb icon={icon} title={title} description={description} />
                </FadeIn>
              );
            })}
        </div>
      </Container>
    </S.AboutUs>
  );
};

export default AboutUs;
