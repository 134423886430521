import React from 'react';
import * as S from './mainPitch.styled';
import SvgIcon from '../../utils/SvgIcon';
import CustomLink from '../../utils/CustomLink';
import FadeIn from '../../utils/FadeIn';

const MainPitch = ({ data, overlap, bgColor }) => {
  return (
    <S.MainPitch overlap={overlap} bgColor={bgColor}>
      <S.ContainerWrapper>
        <div className="cards">
          {data.length > 0 &&
            data.map((item, idx) => {
              const { title, description, linkType, linkLabel, linkURL, icon } =
                item;
              return (
                <FadeIn key={idx}>
                  <div className="card u-max-width-70">
                    <SvgIcon icon={icon} />
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </div>
                </FadeIn>
              );
            })}
        </div>
      </S.ContainerWrapper>
    </S.MainPitch>
  );
};

export default MainPitch;
