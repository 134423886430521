import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Heading from '../../elements/Heading/Heading.component';
import Button from '../../elements/Button/Button.component';
import * as S from './hero.styled';
import FadeIn from '../../utils/FadeIn';

const Hero = ({ data }) => {
  const { tagline, heading, paragraph1, cta, image1 } = data;
  const { linkType, linkLabel, linkURL } = cta;
  const { image, imageAlt } = image1;
  const heroImage = getImage(image);
  return (
    <S.Hero>
      <S.ContainerWrapper>
        <FadeIn>
          <S.Contents className="u-max-width-70">
            <Heading tagline={tagline} title={heading} inverseColor />
            <p>{paragraph1}</p>
            <Button linkType={linkType} linkURL={linkURL}>
              {linkLabel}
            </Button>
          </S.Contents>
        </FadeIn>
        <FadeIn>
          <GatsbyImage
            className="hero-image u-max-width-70"
            image={heroImage}
            alt={imageAlt}
            style={{ display: 'block' }}
          />
        </FadeIn>
      </S.ContainerWrapper>
    </S.Hero>
  );
};

export default Hero;
