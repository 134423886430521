import styled from 'styled-components';
import Container from '../../layout/Container';

export const MainPitch = styled.div`
  padding: 10rem 0;
  background-color: ${({ bgColor }) =>
    bgColor ? 'var(--color-bg-light)' : 'var(--color-white)'};
  padding: ${({ overlap }) => (overlap ? '0 0 10rem 0' : '10rem 0')};
  .cards {
    width: 100%;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    margin-top: -7rem;
    .card {
      height: 100%;
      padding: 5rem 2rem;
      text-align: center;
      background-color: var(--color-white);
      border-radius: 0.6rem;
      box-shadow: var(--box-shadow-1);
      transition: transform 500ms;
      > * + * {
        margin-top: 2rem;
      }
      svg {
        fill: var(--color-primary);
        width: 5rem;
        height: 5rem;
      }
      a {
        display: block;
        color: var(--color-primary);
        &:hover {
          color: var(--color-secondary);
        }
      }
      &:hover {
        transform: translateY(-8px);
      }
    }
  }
`;

export const ContainerWrapper = styled(Container)``;
