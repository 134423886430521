import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Testimonials from '../../elements/Testimonials/Testimonials.component';
import Heading from '../../elements/Heading/Heading.component';
import Container from '../../layout/Container';
import FadeIn from '../../utils/FadeIn';
import * as S from './africanProverbs.styled';

const AfricanProverbs = ({ data }) => {
  const { tagline, heading, image1, quotes } = data;
  const img = getImage(image1.image);
  return (
    <S.AfricanProverbs>
      <Container className="container">
        <FadeIn>
          <GatsbyImage
            image={img}
            alt="image"
            className="u-max-width-70"
            style={{ display: 'block' }}
          />
        </FadeIn>
        <FadeIn>
          <div className="contents u-max-width-70">
            <Heading tagline={tagline} title={heading} />
            <Testimonials data={quotes} />
          </div>
        </FadeIn>
      </Container>
    </S.AfricanProverbs>
  );
};

export default AfricanProverbs;
