import React, { useState, useEffect } from 'react';
import * as S from './testimonials.styled';

const Testimonials = ({ data }) => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const lastIndex = data.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, data]);
  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 5000);
    return () => clearInterval(slider);
  }, [index]);
  return (
    <S.Container>
      <S.Testimonials>
        {data.length > 0 &&
          data.map((el, i) => {
            const { text, image1, name, occupation } = el;
            let status = 'nextSlide';
            if (i === index) {
              status = 'activeSlide';
            }
            if (i === index - 1 || (index === 0 && i === data.length - 1)) {
              status = 'lastSlide';
            }
            return (
              <S.TestimonialComp
                className={status}
                text={text}
                photo={image1.image}
                name={name}
                occupation={occupation}
              />
            );
          })}
      </S.Testimonials>
      <S.BtnWrapper>
        <div onClick={() => setIndex(index - 1)}>
          <S.Icon icon="previous" />
        </div>
        <div onClick={() => setIndex(index + 1)}>
          <S.Icon icon="next" />
        </div>
      </S.BtnWrapper>
    </S.Container>
  );
};

export default Testimonials;
