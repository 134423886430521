import styled, { keyframes } from 'styled-components';

const outline1 = keyframes`
0% {
      width: 0;
      height: 0;
      border-top-color: var(--color-primary);
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
    50% {
      width: 105%;
      height: 0;
      border-top-color: var(--color-primary);
      border-right-color: var(--color-primary);
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
    100% {
      width: 105%;
      height: 105%;
      border-top-color: var(--color-primary);
      border-right-color: var(--color-primary);
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
`;

const outline2 = keyframes`
0% {
      width: 0;
      height: 0;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: var(--color-primary);
    }
    50% {
      width: 0;
      height: 105%;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: var(--color-primary);
      border-left-color: var(--color-primary);
    }
    100% {
      width: 105%;
      height: 105%;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: var(--color-primary);
      border-left-color: var(--color-primary);
    }
`;

export const WhatWeDo = styled.div`
  background-color: var(--color-secondary);
  color: var(--color-grey-3);
  padding: 10rem 0 20rem 0;

  .container {
    flex-direction: column;
    > * + * {
      margin-top: 5rem;
    }

    .desc {
      text-align: center;
      margin-top: 2rem;
    }

    .cards {
      width: 100%;
      display: grid;
      grid-gap: 3rem;
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
      .card {
        background: transparent;
        position: relative;
        height: 100%;
        padding: 5rem 2rem;
        text-align: center;
        border-radius: 6px;
        border: 3px dashed var(--color-grey-5);
        > * + * {
          margin-top: 2rem;
        }
        svg {
          fill: var(--color-grey-3);
          width: 5rem;
          height: 5rem;
        }
        a {
          position: relative;
          display: block;
          color: var(--color-primary);
          z-index: 100;
          &:hover {
            color: var(--color-grey-3);
          }
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          background: transparent;
          border: 3px solid transparent;
        }
        &::before {
          top: -3px;
          right: -3px;
        }
        &::after {
          bottom: -3px;
          left: -3px;
        }
        &:hover {
          &::before {
            animation: ${outline1} 500ms linear forwards;
          }
          &::after {
            animation: ${outline2} 500ms linear forwards;
            animation-delay: 500ms;
          }
        }
      }
    }

    > *:last-child {
      margin-bottom: -30rem;
    }

    .cta {
    }
  }
`;
