import styled from 'styled-components';
import Testimonial from '../Testimonial/Testimonial.component';
import SvgIcon from '../../utils/SvgIcon';

export const Container = styled.div``;

export const Testimonials = styled.div`
  display: flex;
  position: relative;
  height: 30rem;
  width: 100%;
  overflow: hidden;
`;

export const TestimonialComp = styled(Testimonial)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
  opacity: 0;
  flex-shrink: 0;

  &.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
  &.lastSlide {
    transform: translateX(-100%);
  }
  &.nextSlide {
    transform: translateX(100%);
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  > * + * {
    margin-left: 0.5rem;
  }
`;

export const Icon = styled(SvgIcon)`
  width: 3rem;
  height: 3rem;
  fill: var(--color-grey-6);
  cursor: pointer;
  &:hover {
    fill: var(--color-grey-7);
  }
`;
