import React from 'react';
import * as S from './blurb.styled';

const Blurb = ({ icon, title, description }) => {
  return (
    <S.Blurb>
      <S.Icon icon={icon} label={icon} />
      <S.Contents>
        <h3>{title}</h3>
        <p>{description}</p>
      </S.Contents>
    </S.Blurb>
  );
};

export default Blurb;
