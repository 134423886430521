import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout/Layout';
import Hero from '../components/sections/Hero/Hero.component';
import MainPitch from '../components/sections/MainPitch/MainPitch.component';
import WhatWeDo from '../components/sections/WhatWeDo/WhatWeDo.component';
import AboutUs from '../components/sections/AboutUs/AboutUs.component';
import AfricanProverbs from '../components/sections/AfricanProverbs/AfricanProverbs.components';
import Partners from '../components/sections/Partners/Partners.component';
// import Blog from '../components/sections/Blog/Blog.component';

const IndexPage = ({ data }) => {
  const { hero, mainPitch, whatWeDo, aboutUs, partners, proverbs, blog, seo } =
    data.markdownRemark.frontmatter;
  const { seoTitle, seoDescription, browserTitle } = seo;
  return (
    <Layout>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <Hero data={hero} />
      <MainPitch data={mainPitch} overlap />
      <AboutUs data={aboutUs} invertBg />
      <WhatWeDo data={whatWeDo} />
      <AfricanProverbs data={proverbs} />
      <Partners data={partners} />
      {/* <Blog data={blog} /> */}
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hero {
          tagline
          heading
          paragraph1
          cta {
            linkType
            linkLabel
            linkURL
          }
          image1 {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            imageAlt
          }
        }
        mainPitch {
          icon
          title
          description
          linkLabel
          linkType
          linkURL
        }
        whatWeDo {
          tagline
          heading
          paragraph1
          cards {
            title
            icon
            description
            linkLabel
            linkType
            linkURL
          }
          cta {
            description
            title
            linkLabel
            linkType
            linkURL
          }
        }
        aboutUs {
          tagline
          heading
          description
          paragraph1
          paragraph2
          blurbs {
            icon
            title
            description
          }
          image1 {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 270
                  height: 600
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
            imageAlt
          }
          image2 {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 270
                  height: 250
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
            imageAlt
          }
          image3 {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 270
                  height: 250
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
            imageAlt
          }
        }
        partners {
          description
          label
          logo
        }
        proverbs {
          tagline
          heading
          description
          image1 {
            imageAlt
            image {
              childImageSharp {
                gatsbyImageData(width: 1620, height: 1875)
              }
            }
          }
          quotes {
            text
            occupation
            name
            image1 {
              imageAlt
              image {
                childImageSharp {
                  gatsbyImageData(width: 80, height: 80)
                }
              }
            }
          }
        }
        blog {
          heading
          tagline
          linkLabel
          linkType
          linkURL
          news {
            title
            description
            linkLabel
            linkType
            linkURL
            image1 {
              imageAlt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        seo {
          browserTitle
          title
          description
        }
      }
    }
  }
`;
