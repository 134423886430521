import styled from 'styled-components';
import media from '../../layout/mediaQuery';

export const AfricanProverbs = styled.div`
  background-color: var(--color-bg-light);
  padding: 20rem 0 10rem 0;

  .container {
    > * {
      width: calc(100% / 2);
    }
    .contents {
      background-color: var(--color-white);
      padding: 6rem 3rem 3rem 3rem;
      > * + * {
        margin-top: 2rem;
      }
    }
  }

  ${media.tabPort`
    .container {
        flex-direction: column;
        > * {
          width: 100%;
        }
      }
  `}
`;
