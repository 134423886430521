import React from 'react';
import * as S from './partners.styled';
import SvgIcon from '../../utils/SvgIcon';
import Container from '../../layout/Container';
import FadeIn from '../../utils/FadeIn';
import Heading from '../../elements/Heading/Heading.component';

const Partners = ({ data }) => {
  return (
    <S.Partners>
      <Container className="container">
        <Heading title="Our Partners" tagline="Collaboration" center />
        <div className="partners">
          {data.length > 0 &&
            data.map((item, idx) => (
              <FadeIn key={idx}>
                <div className="partner" data-tooltip={item.description}>
                  <SvgIcon icon={item.logo} label={item.logo} />
                </div>
              </FadeIn>
            ))}
        </div>
      </Container>
    </S.Partners>
  );
};

export default Partners;
