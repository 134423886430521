import React from 'react';
import Heading from '../../elements/Heading/Heading.component';
import FadeIn from '../../utils/FadeIn';
import Container from '../../layout/Container';
import SvgIcon from '../../utils/SvgIcon';
import CustomLink from '../../utils/CustomLink';
import CallToAction from '../../elements/CallToAction/CallToAction.component';
import * as S from './whatWeDo.styled';

const WhatWeDo = ({ data }) => {
  const { tagline, heading, paragraph1, cards, cta } = data;
  return (
    <S.WhatWeDo id="services">
      <Container className="container">
        <FadeIn>
          <Heading
            className="u-max-width-70"
            tagline={tagline}
            title={heading}
            inverseColor
            center
          />
          <p className="desc u-max-width-70">{paragraph1}</p>
        </FadeIn>
        <div className="cards">
          {cards.length > 0 &&
            cards.map((item, idx) => {
              const { title, description, linkType, linkLabel, linkURL, icon } =
                item;
              return (
                <FadeIn key={idx}>
                  <div className="card u-max-width-70">
                    <SvgIcon icon={icon} />
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </div>
                </FadeIn>
              );
            })}
        </div>
        <FadeIn>
          <CallToAction className="cta" data={cta} />
        </FadeIn>
      </Container>
    </S.WhatWeDo>
  );
};

export default WhatWeDo;
