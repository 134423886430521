import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as S from './testimonial.styled';

const Testimonial = ({ className, text, photo, name, occupation }) => {
  const img = getImage(photo);
  return (
    <S.Testimonial className={className}>
      <p className="quote">{text}</p>
      <GatsbyImage image={img} alt="image" style={{ borderRadius: '50%' }} />
      <div className="description">
        <h3>
          {name}
          <span>{occupation}</span>
        </h3>
      </div>
    </S.Testimonial>
  );
};

export default Testimonial;
