import styled from 'styled-components';
import SvgIcon from '../../utils/SvgIcon';

export const Blurb = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 4rem;
    width: 90%;
  }
`;

export const Icon = styled(SvgIcon)`
  width: 5rem;
  height: 5rem;
  fill: var(--color-primary);
`;

export const Contents = styled.div``;
