import React from 'react';
import * as S from './button.styled';

const Button = ({ children, linkType, linkURL, outline }) => {
  return (
    <S.Button linkType={linkType} linkURL={linkURL} outline={outline}>
      {children}
    </S.Button>
  );
};

export default Button;
