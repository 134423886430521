import styled from 'styled-components';
import media from '../../layout/mediaQuery';

export const Partners = styled.div`
  background-color: var(--color-bg-light);
  padding: 4rem 0 10rem 0;

  .container {
    flex-direction: column;
    > * + * {
      margin: 3rem 0 0 0;
    }
    .partners {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      > * {
        svg {
          fill: var(--color-grey-6);
          width: 25rem;
          /* height: 25rem; */
        }
        &:nth-child(2) svg {
          width: 15rem;
          height: 15rem;
        }
      }
      .partner {
        --scale: 0;
        --tooltip-color: var(--color-secondary);
        --arrow-size: 0.8rem;
        font-size: 1.3rem;
        position: relative;

        &:hover {
          --scale: 1;
          svg {
            fill: var(--color-grey-7);
          }
        }

        &::before,
        &::after {
          position: absolute;
          left: 50%;
          transform: translateX(-50%) translateY(var(--translate-y, 0))
            scale(var(--scale));
          transition: 150ms transform;
        }
        &::before {
          --translate-y: calc(-100% - var(--arrow-size));
          content: attr(data-tooltip);
          width: max-content;
          max-width: 100%;
          padding: 1rem;
          border-radius: 0.5rem;
          text-align: center;
          color: var(--color-grey-3);
          background: var(--tooltip-color);
          transform-origin: bottom center;
        }
        &::after {
          --translate-y: calc(-1 * var(--arrow-size));
          content: '';
          border: var(--arrow-size) solid transparent;
          border-top-color: var(--tooltip-color);
          transform-origin: top center;
        }
      }
    }
  }

  ${media.phone`
      .container {
        .partners {
          flex-direction: column;
          > * + * {
            margin: 4rem 0 0 0;
          }
        }
      }  
  `}
`;
