import styled from 'styled-components';

export const Testimonial = styled.div`
  background-color: var(--color-bg-light);
  padding: 2rem;
  text-align: center;

  > * + * {
    margin-top: 2rem;
  }

  .description {
    h3 {
      span {
        display: block;
        font-weight: 400;
        font-size: 1.3rem;
      }
    }
  }
`;
