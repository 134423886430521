import styled from 'styled-components';
import media from '../../layout/mediaQuery';

export const CallToAction = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--color-grey-1);
  background-color: var(--color-primary);
  width: 100%;
  padding: 2rem 1rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  position: relative;

  ${media.tabPort`
  flex-direction: column;
  text-align: center;
  > * + * {
    margin: 3rem 0 0 0;
  }
  `}
`;

export const Text = styled.div`
  flex-basis: 70rem;
  h2 {
    font-size: 2.5rem;
  }
  > * + * {
    margin-top: 1rem;
  }
  ${media.tabPort`
  flex-basis: unset;
  `}
`;
