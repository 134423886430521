import styled from 'styled-components';
import media from '../../layout/mediaQuery';

export const AboutUs = styled.div`
  background: linear-gradient(
      270deg,
      ${({ invertBg }) =>
          invertBg ? 'var(--color-bg-light)' : 'var(--color-white)'}
        0,
      ${({ invertBg }) =>
          invertBg ? 'var(--color-bg-light)' : 'var(--color-white)'}
        55%,
      transparent 0,
      transparent 55%
    ),
    linear-gradient(
      0deg,
      ${({ invertBg }) =>
          invertBg ? 'var(--color-bg-light)' : 'var(--color-white)'}
        0,
      ${({ invertBg }) =>
          invertBg ? 'var(--color-bg-light)' : 'var(--color-white)'}
        50%,
      transparent 0,
      transparent 50%
    ),
    ${({ invertBg }) =>
      invertBg ? 'var(--color-white)' : 'var(--color-bg-light)'};

  padding: 10rem 0 20rem 0;

  .container {
    > * {
      width: calc(100% / 2 - 1rem);
    }

    > * + * {
      margin: 0 0 0 2rem;
    }

    .images {
      display: flex;

      > * {
        width: calc(100% / 2 - 2rem);
      }
      > * + * {
        margin: 0 0 0 2rem;
      }
      .image-short {
        > * + * {
          margin: 2rem 0 0 0;
        }
      }
      .image-long {
        margin-top: 10rem;
      }
    }

    .contents {
      > * + * {
        margin-top: 2rem;
      }
    }

    ${media.tabPort`
        flex-direction: column;
        > * {
          width: 100%;
        }
        > * + * {
          margin-top: 15rem;
        }
    `}
  }
`;
