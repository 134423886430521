import styled from 'styled-components';
import CustomLink from '../../utils/CustomLink';

export const Button = styled(CustomLink)`
  display: inline-block;
  text-align: center;
  padding: 1.5rem 2rem;
  min-width: max-content;
  color: ${(props) =>
    props.outline ? 'var(--color-secondary)' : 'var(--color-grey-2)'};
  background-color: ${(props) =>
    props.outline ? 'transparent' : 'var(--color-accent)'};
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 200ms ease;
  ${(props) => props.outline && 'border: 1px solid var(--color-primary);'}

  &:hover {
    background-color: ${(props) =>
      props.outline ? 'var(--color-primary)' : 'var(--color-grey-2)'};
    color: ${(props) =>
      props.outline ? 'var(--color-grey-2)' : 'var(--color-primary)'};
  }
`;
