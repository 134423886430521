import React from 'react';
import * as S from './callToAction.styled';
import Button from '../Button/Button.component';

const CallToAction = ({ className, data }) => {
  const { title, description, linkType, linkURL, linkLabel } = data;
  return (
    <S.CallToAction className={className}>
      <S.Text>
        <h2>{title}</h2>
        <p>{description}</p>
      </S.Text>
      <Button linkType={linkType} linkURL={linkURL}>
        {linkLabel}
      </Button>
    </S.CallToAction>
  );
};

export default CallToAction;
