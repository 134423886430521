import styled from 'styled-components';
import Container from '../../layout/Container';
import media from '../../layout/mediaQuery';

export const Hero = styled.div`
  color: var(--color-grey-3);
  background: url('img/Sun-Tornado.svg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15rem 0 20rem 0;

  .hero-image {
    padding-bottom: 70px;
    border-radius: 5px;
    animation: moveInRight 2s ease-out;
  }
`;

export const ContainerWrapper = styled(Container)`
  > * {
    width: calc(100% / 2 - 1rem);
  }
  > * + * {
    margin: 0 0 0 2rem;
  }

  ${media.tabPort`
    flex-direction: column;
    > * + * {
        margin: 2rem 0 0 0;
    }
    > * {
        width: 100%;
        margin: 0 auto;
    }
 `}
`;

export const Contents = styled.div`
  animation: moveInLeft 2s ease-out;

  > * + * {
    margin: 2rem 0 0 0;
  }

  ${media.tabPort`
    /* text-align: center; */
    margin-bottom: 12rem;
 `}
`;
